.contatti{
    &__social {
        display: flex;
        align-items: center;
        p {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
        >* {
            margin-right: 10px !important;
            white-space: nowrap;
        }
        a {
            text-transform: capitalize;
            text-decoration: none;
            align-items: center;
            display: flex;
        }
        img {
            height: 32px;
            margin-right: 5px;
        }
    }

    &__links {
        list-style: none;
        padding: 0;
        margin: 10px 0 0;
        display: flex;
        flex-wrap:wrap;
        a{
            align-items: center;
            display: flex;
            text-decoration: none;
        }
    }

    &__link {
        flex: 0 1 auto;
        margin-bottom: 20px;
        width: 100%;
    }

    &__icona {
        overflow: hidden;
        margin-right: 20px;
        /*background-color: $sandy-brown;*/
        border-radius: 50%;
        text-align: center;
        padding: 6px;
        height:64px;
        width:64px;
        img{ 
            display:inline-block;
            width: 100%;
        }
    }

    &__label{
        font-weight: $font-weight-semibold;
    }

    &__arrow{
        margin-left: auto;
        font-size: 40px;
    }
}

#leafletMap {
    width: 100%;
    height: 445px;
    border:1px solid $theme-gray;
    @include media-breakpoint-down(xs) { 
        height: 300px; 
        margin-bottom: 30px;
    }
}