.prodotto{
    padding-top: 15px;

    &__header{
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        /*.button{
            padding: 10px 20px;
        }*/

        @include media-breakpoint-up(md) {
            flex-direction: row;
            margin-bottom: 0;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__pretitle {
        font-size: 22px;
        font-weight: $font-weight-bold;
        color: $theme-color;
        margin-bottom: 7px;
    }

    &__nota{ 
        font-size: 8px;
        color: $dove-gray;
        line-height: 12px;
    } 

    &__back{
        display: block;  
        color: $theme-color; 
        font-weight: $font-weight-bold;
        text-decoration:none !important; 
        i{
            padding-right: 5px; 
        } 
    } 

    &__pulsanti{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .table tr:first-child td{
        border-top: none;
    }
}  