.retecommerciale{

    max-width: 690px;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 15px;

    @media screen and (max-width: 360px){
        padding-top: 30px;
    }

    @include media-breakpoint-up(sm) {
        max-width: 510px;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-up(md) {
        max-width: 690px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0;
        max-width: none;
    }

    &--loading::after{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        content:"";
        background: $white url('/images/lazyLoader.gif') no-repeat center center;
    }
    .filter{
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }

        &__link{
            flex: 1 1 auto;
            width: 33.33332%;
            text-align: center; 
            cursor: pointer;
            height: $rc-filter-height;
            line-height: $rc-filter-height;
            color: $white;
            font-weight: $font-weight-semibold;
            font-family: $font-secondary;
            font-size: 16px;
            background-color: $mine-shaft;
            border-right: 1px solid $white;
            border-bottom: 1px solid $white;
            @include anim_all;

            @include media-breakpoint-up(md) {
                width: auto;
            }
            
            &:nth-child(3){
                border-right: none;
                @include media-breakpoint-up(md) {
                    border-right: 1px solid $white;
                }
            }

            &:last-child{
                border-right: none;
            }

            &:hover, &.active {
                background-color: $theme-color;
            }
        }
    }

    &__map{

        height: calc(100vh - #{$pad-top + $rc-filter-height * 2});

        @include media-breakpoint-up(md) {
            height: calc(100vh - #{$pad-top + $rc-filter-height});
        }

        @include media-breakpoint-up(lg) {
            height: calc(100vh - #{$pad-lg-top + $rc-filter-height});
        }
    }
} 