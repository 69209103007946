pre {
	position:fixed;
	left:0;
	top:0;
	text-align: left;
	text-transform: none;
	width:325px;
	height:100%;
	overflow-y:auto;
	line-height: 11px;
	font-size: 11px;
	letter-spacing: 0;
	word-spacing: 0;
	z-index:999999999999999;
	padding: 10px;
	margin: 0;
	word-break:normal;
	word-wrap:normal;
	font-family: arial;
	box-sizing: content-box !important;
	overflow-y:auto;
	background-color: rgba(248,248,248,0.9);
	&.hidden {
	  display: none;
	}
	&.autow {
		width:auto;
	}
}
.pre_label {
	position:fixed;
	top:0;
	right:0;
	padding: 2px 10px;
	background-color: red;
	color: white; 
	z-index: 999999; 
}

.pointer{
  cursor:pointer;
}
.col-tw{
  width:20%;
  float: left;
  margin:0;
}
.unboxed *,
.unboxed *:after, 
.unboxed *:before{
	box-sizing: content-box !important;
}
.anim,
.anim:before,
.anim:after{
  transition: all 0.2s ease-in-out;
}
.anim-back,
.anim-back:before,
.anim-back:after{
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

// Funzione per gestione rem automatica rispetto al font-base del sito.
@function rem($pixels, $context: $theme-font-base) {
  @if (unitless($pixels)) {
	$pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
	$context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}


@mixin bg-set-contain{
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin bg-set-cover{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}