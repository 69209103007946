.header {
    background-color: $white;
    box-shadow: 0 0 20px rgba($black, .2);
    
    &__info {
        display: flex;
        font-size: 14px;
        width: 100%;
        justify-content: space-between;

        a{
            color: $white;
            font-weight: 700;
            line-height: 28px;
        }

        &__wrapper{
            background-color:$mine-shaft;
            margin-bottom: 10px;
        }
    }
    
    &__right {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            height: calc(100vh - #{$pad-top});
            justify-content: start;
        }

        @include media-breakpoint-down(xs) {
            height: calc(100vh - #{$pad-xs-top});
        }
    }
    &__logo {
        display: block;
    }
    a {
        text-decoration: none;
        &.active{
            color:$theme-color !important;
        }
    }
    ul {
        list-style-type: none;
        margin: 0;
    }

    // Pulsante mobile campo di ricerca

    &__mbl-btn{
        display: block;
        color: $dove-gray;
        margin: 0;
        cursor: pointer;
        font-size:13px;
        font-weight: $font-weight-normal;
        margin-left: auto;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    
    // Pulsante desktop campo di ricerca
    &__dsk-btn{
        display: none;

        @include media-breakpoint-up(lg) {
            color: $dove-gray;
            margin: 0;
            cursor: pointer;
            font-weight: $font-weight-normal;
            margin-left: 50px;
            display: block;
            white-space: nowrap;
        }
        i {
            display: inline-block !important;
            margin-right: 7px;
        }
    }

    // Campo di ricerca
    &__cerca{
        padding: 0;
        margin: 0;
        width: 100%;
        display: none;
        background-color: $white;
        .widget_search{
            list-style: none;
        }

        @include media-breakpoint-up(lg) {
            box-shadow: 0 10px 10px rgba($black, .07);
            position: absolute;
            top: 100%;
            left: 0;
        }

        button{
            position: absolute;
            top: 0;
            right: 15px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        input{
            margin-bottom: 15px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
            }
        }
    }
}


// MOBILE
@include media-breakpoint-down(md) {
    .header {
        &__top {
            order: 2;
        }
        &__logo {
            margin: 5px 0 15px;
            img {
                max-height: 46px;
                width: auto;
                display: block;
            }
        }
        ul {
            padding: 0;
            list-style: none; 
            i,
            ul {
                margin-left: 20px;
            }
            ul {
                display: none;
            }
            a {
                display: block;
                padding: 10px 0;
            }
            li {
                &:hover>a {
                    color: $theme-color;
                }
            }
        }
        &__third {
            a {
                font-size: 15px;
            }
        }
        &__lang {
            .submenu {
                border-top: 1px dotted $dove-gray;
            }
            a {
                font-weight: $font-weight-semibold;
                text-transform: uppercase;
                position: relative;
            }
        }
        &__right {
            display: none;
        }
        &__logo {
            &--flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        &__hamburger {
            height: 30px;
            width: 30px;
            margin-left: 30px;
            background-image: url('/wp-content/themes/mmone_theme/images/menu.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 0 0;
            &.open {
                background-position: 0 -30px;
            }
        }
    }
}

// DESKTOP
@include media-breakpoint-up(lg) {
    .header {
        $this: &;
        padding: 30px 0;
        i {
            display: none;
        }
        .hamburger {
            display: none;
        }
        &__logo {
            margin-top: 15px;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__top,
        &__bottom {
            order: 1;
            display: flex;
            justify-content: flex-end;
        }
        &__lang {
            padding: 0;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            position: relative;
            >li {
                &::after {
                    @include triangolo;
                    left: calc(50% - 10px);
                }
                &:hover {
                    &::after {
                        opacity: 1;
                    }
                    >a {
                        color: $theme-color;
                    }
                    ul {
                        @include visibile;
                    }
                }
            }
            ul {
                @include anim_all;
                @include hidden;
                border-top: 20px solid $white;
                background-color: $main-nav-color;
                z-index: 10;
                position: absolute;
                padding: 20px;
                right: -20px;
                top: 100%;
                text-align: right;
                li {
                    line-height: 30px;
                }
            }
            a {
                white-space: nowrap;
            }
        }
        &__top-menu,
        &__main-menu {
            display: flex;
            padding: 0;
            ul {
                @include anim_all;
                @include hidden;
                position: absolute;
            }
            >li {
                position: relative;
                margin-left: 50px;  
            }
        }
        &__top-menu {
            margin-right: 50px !important;
        }
        &__main-menu {
            >li {
                font-weight: $font-weight-bold;
                &.submenu::after {
                    @include triangolo;
                    left: calc(50% - 5px);
                }
                &:hover {
                    >a {
                        color: $theme-color;
                    }
                    #{$this}__second {
                        @include visibile;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
                >a {
                    font-size: $menu-font-size;
                }
            }
        }
        &__second {
            top: 100%;
            border-top: 20px solid $white;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 50px;
            cursor: pointer;
            position: relative;
            min-height: 100%;
            background-color: $main-nav-color;
            font-weight: $font-weight-normal;
            left: -50px;
            >li {
                >a {
                    font-size: $body-font-size;
                    white-space: nowrap;
                    line-height: 55px;
                    display: block;
                    padding-right: 50px;
                    position: relative;
                }
                &:hover {
                    >a {
                        color: $theme-color;
                        &::after {
                            content: ">";
                            position: absolute;
                            right: 20px;
                            line-height: 55px;
                        }
                    }
                    #{$this}__third {
                        @include visibile;
                    }
                }
            }
        }
        &__third {
            @include anim_all;
            position: absolute;
            background-color: $main-nav-color;
            padding-top: 35px;
            padding-bottom: 35px;
            padding-right: 50px;
            padding-left: 100%;
            // transform: translateX(10px);
            top: 0;
            left: 0;
            min-height: 100%;
            z-index: -1;
            // background-color:#ff0000;
            // border-bottom: 20px solid #ff0000;
            li:first-child {
                white-space: nowrap;
            }
            a {
                font-size: 15px;
                white-space: nowrap;
            }
        }
    }
}