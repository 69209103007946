.azienda{
    h3, h2, .h3, .h2{
        color: $theme-color;
    }

    &__blocco{
        padding-top: 30px;
    }

    &__certificazioni{
        display: flex;
        list-style: none;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 60px;
        &__item {
            margin: 10px;
        }
    }
}