.news{
    padding-top: $pad-top-section/2;
    @include media-breakpoint-up(lg) {
        padding-top: $pad-top-section;
    }
    &--nopad {
        padding-top:0;
    }
    &__elenco{
        padding: 0;
        list-style: none;
        margin: 30px 30px 0;

        // nascondo gallery prima del caricamento
        display: none;

        &.slick-initialized {
            display: block;
        }

        @include media-breakpoint-up(lg) { 
            margin: 30px -10px 0;
        }
        &--noslick{
            margin: 0 -10px 0;
            display: flex;
            flex-wrap: wrap; 
            li{
                flex: 0 0 auto;
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 50%;
                }

                @include media-breakpoint-up(lg) {
                    width: 33.33332%; 
                }
            }
        }
    }

    &__item {
       
        &__content{
            margin: 10px;
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(sm) {
                flex-direction: row;
            }
        }

        &__cmgs{
            min-width: 130px;
            margin-right: 0;
            margin-bottom: 15px;
            @include media-breakpoint-up(sm) {
                margin-right: 15px;
            }
        }

        &__title{
            font-size: 18px;
            line-height: 24px;
            font-weight: $font-weight-semibold ;
            text-transform: uppercase;
            a{
                text-decoration: none;
            }
        }
        &__desc{
            font-size: 16px;
            line-height: 21px;
            color: $dove-gray;
            strong, b{
                display: block;
                margin-top: 0.5rem;
                color: $theme-color;
                font-style: italic;
            }
        }
    }
}