.closeup {
    padding-top: $pad-top-section/2;
    @include media-breakpoint-up(lg) {
        padding-top: $pad-top-section;
    }
    
    &__text {
        display: flex;
        flex-direction: column;
        &__btn {
            //margin-top: auto;
            margin: 10px 0 20px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }
        &--order-2 {
            order: 0;
            @include media-breakpoint-up(md) {
                order: 2;
            }
            @include media-breakpoint-up(lg) {
                order: 0;
            }
        }
    }
}