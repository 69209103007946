.category{
    a{
        text-decoration: none;
    }
    &__block{
        border-radius: 20px;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        background-color: transparent;
        &--nolink a{
            cursor: auto !important;
        }
    }

    &__image{
        width: 100%;
        height: 0;
        position: relative;
        background-color: $mine-shaft;
        padding-bottom: calc(0.75 * 100%);
        /*@include media-breakpoint-up(sm) {
            padding-bottom: calc(0.45 * 100%);
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: calc(0.75 * 100%); // formato 4/3
        }*/
        &--light{
            background-color: transparent;
        }
        
        .withLoader{
            width: 100%;
            height: 100%;
            position: absolute;
            &:not(.withLoader--light)::before{
                background-color: rgba($black, .4);
                position:absolute;
                top:0;
                left:0;
                content:"";
                width:100%;
                height:100%;
            }
        }

        .withLoader--light{
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    &__icon{
        display:block;
        margin: 5px 20px;
        width:70px;
    }

    &__title {
        top:0;
        left:0;
        margin:0;
        z-index:20;
        width:100%;
        height: 100%;
        display:flex;
        position:absolute;
        text-shadow: 0 0 10px rgba($black, .2);
        a{
            padding: 10px;
            text-align: left;
            color: $white;
            margin: auto 0;
            text-decoration: none;
            @include media-breakpoint-up(lg) {
                padding: 20px;
                text-align: center;
                margin: auto;
            }
            
        }
        &--static{
            position: static;
            text-shadow: none;
            width: auto;
            height: auto;
            a{
                padding: 10px;
                color: $black;
            }
        }
    }

    &__info{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        background-color: $theme-gray;
        @include media-breakpoint-up(lg) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__click{
        cursor: pointer;
        background-color: $theme-color;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        font-size: $body-font-size;
        font-weight: $font-weight-semibold;
        color: $white;
        padding: 10px;
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
    }

    &__subcategory{
        list-style: none;
        padding: 0;
        margin: 0;
        display: none;
        &__title, a{
            background-color: $theme-gray;
            display: block;
            padding: 10px 20px;
            font-size: 15px;
        }
        &__title{
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            font-size: 14px;
        }
        li:last-child a{
            padding-bottom: 20px;
        }

        &__link--nolink{
            cursor: auto !important;
            color: $black !important;
        }
    } 
 
    &__product{
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        background-color: transparent;
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: box-shadow 400ms linear;
        box-shadow: 0 0 15px rgba($black, .23);

        /*&:hover{
            box-shadow: 0 0 10px rgba($black, .4);
        }*/

        &__btn{
            margin-top: auto;
            margin-bottom: 20px;
        }

        &__title{
            padding: 0 10px;
        }
    }

    &__back{
        display: block;  
        color: $theme-color; 
        font-weight: $font-weight-bold;
        text-decoration:none !important; 
        i{
            padding-right:5px; 
        } 
    } 
    &__seo{
        padding-top: 30px;
    }
}