// Variabile media query XXS
$screen-xxs-max:480px;
// $border-radius-base:        0;
// $border-radius-large:       0;
// $border-radius-small:       0;
// $input-border-focus: $theme-brown;
$white:         #fff;
$black:         #000;
$theme-color:   #E8660C;
// $theme-color:   #00923f;
$sandy-brown:   #F49957;
$mine-shaft:    #262626; 
$theme-gray:    #ededed;
$san-marino:    #3F799D;
$alto:          #D9D9D9;
$concrete:      #f2f2f2;
$dove-gray:     #707070;
$sandwisp:      #F7F2B1;



$bg-footer: $mine-shaft !default;
$color-font-default: $black !default;
$main-nav-color: $theme-gray;

// Easings
$mm-easing-back:cubic-bezier(0.68, -0.55, 0.265, 1.55);
$mm-easing-bounce:cubic-bezier(0, 1.8, 1, 1.8);

// Social
$theme-fb-color:    #3a589b;
$theme-tw-color:    #568bc9;
$theme-gp-color:    #d6492f;
$theme-pt-color:    #ca4638;
$theme-yt-color:    #C3181E;
$theme-rss-color:   #e19126;



// =============================================================================
// TYPOGRAPHY
// =============================================================================
$font-primary: "Open Sans", Arial, sans-serif;
$font-secondary: "Nunito", sans-serif;
$body-font-size: 18px;
$body-font-size-small: 16px;
$root-font-size: 10px;
$menu-font-size: 22px;
$body-line-height: 24px;

// weights
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Padding:
$pad-xs-top: 114px; 
$pad-top: 90px;
$pad-lg-top: 140px;
$pad-top-section: 90px;

$rc-filter-height: 40px;

@function max($numbers...){
  @return m#{a}x(#{$numbers})
}

@function min($numbers...){
  @return m#{i}n(#{$numbers})
}