.gallery {
    $this: &;
    padding: 0;
    list-style: none;
    margin: 0 30px 20px;


    // nascondo gallery prima del caricamento
    visibility:hidden;
    opacity: 0;	 
    transition: opacity 1s;

    &.slick-initialized {
        visibility:visible;
        opacity:1;
    }

    @include media-breakpoint-up(md) {
        margin: 0 -10px 20px;
    }
 
    // TECNOLOGIA
    &--tecno {
        padding: 0;
        list-style: none;
        margin: 0 30px;
    }

    // IMPIANTI
    &--impianti{
        padding: 0;
        list-style: none;
        margin: 0 30px;
        @include media-breakpoint-up(lg) {
            margin: 0 -10px;
            display: flex;
            flex-wrap: wrap; 
            opacity: 1;
            visibility: visible !important;
        }
        #{$this}__item {
            @include media-breakpoint-up(lg) {
                display: block !important;
                visibility: visible !important;
                width: calc(50% - 20px);
                &:nth-child(even) {
                    transform: translateY(40px);
                }
            }
        }
    }

    //STORIA
    &--storia {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
        &::after{
            background: linear-gradient(90deg, $white 0%,  transparent 50%, $white 100%);
            pointer-events: none;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .slick-track{ 
            &::after{
                content: "";
                position: absolute;
                height: 1px;
                background-color: $alto;
                bottom: 15px;
                left: 0;
                width: 100%;
            }
        }
        .slick-slide{
            padding-bottom: 15px;
            transform: scale(1, 1);
            transition: transform 0.3s;
            position: relative;
            z-index: 1;
            cursor: pointer;
            outline: none !important;
   
            &::after{
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                bottom: 3px;
                left: 50%;
                border-radius: 50%;
                transform: translateX(-50%) scale(0, 0); 
                background-color: $theme-color;
                transition: transform  0.3s;
            }

            &.slick-current{
                transform: scale(1.3, 1.3);
                position: relative;
                .gallery__item__year{
                    color: $san-marino;
                }
                &::after{
                    transform: translateX(-50%) scale(1, 1); 
                }
            }
        }
    }


    &__item {
        $this: &;
        margin: 10px;
        height: inherit !important;

        // nascondo tutti gli item tranne il primo in fase di caricamento gallery
        display: none;
        &:first-child {
            display: block; 
            visibility: hidden;
        }

        &--last-child{ 
            &:last-child #{$this}__content {
                @include media-breakpoint-up(lg) {
                    padding: 0 10px;
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }

        &--height-100 {
            display: flex;
            flex-direction: column;
        }

        &__content {
            text-decoration: none !important;
            width: 100%;
            height: 100%;
            transition: box-shadow 0.3s linear;

            @include media-breakpoint-up(lg) {
                min-height: auto;
            }

            &--flex-gray{
                background-color: $theme-gray;
                border-radius: 10px;
                display: block;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
         
                &:hover {
                    color: inherit;
                    box-shadow: 0 0 10px rgba($black, .4);
                    // background-color: mix($theme-gray, $black, 91%);
                }
            }

            &--transparent{
                @include media-breakpoint-up(md) {
                    background-color: transparent !important;
                }
            }

            &__title {
                font-size: 20px;
                font-weight: $font-weight-semibold;
                text-align: center;
                margin-bottom: 10px;
            }

            &__image {
                margin-bottom: 10px;

                &--tecnologia{
                    max-width: 450px;
                    margin:10px 0 20px;
                    img{
                        width:100%;
                    }
                }
            }

            &__btn {
                color: $san-marino !important;
                font-weight: $font-weight-semibold;
                i {
                    display: none;
                    @include media-breakpoint-up(lg) {
                        display: inline;
                    }
                }
            }

            &__link{
                position: relative;
                display: block;
                height: 100%;

                img {
                    height:100%;
                }
            }

            &__error{
                margin: auto;
            }
        }

        &__small-img{
            height: calc(33.3332% - 15px);
            margin-bottom: 15px;
            position: relative;
            display: block;
            position: relative;
            img{
                object-fit: cover;
                height:100%;
                height:100%;
            }
            .fa-youtube-play{
                font-size: 100% !important;
            }
        }

        &__text{
            p {
                margin-bottom:0;
            }
        }

        &__year {
            color: $dove-gray;
            transition: color 0.3s;
            text-align: center;
            &--blue{
                color: $san-marino;
            }
        }
    }

    // mostro tutti gli item una volta caricata la gallery
    &.slick-initialized .gallery__item {
        display: block;
        visibility: visible !important;
    }


    &--thumb{
        height:100%;

        .slick-list, .slick-track {
            height:100%;
        }
    }

    &__prev,
    &__next {
        position: absolute;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1000;
        height: 25px;
        width: 25px;
        font-size: 20px;
        top: 50%; 
        &.slick-disabled{
            display:none !important;
        } 

        &--white{
            color: $white;
        }
    }

    &__prev {
        left: -25px; 
        &--top{
            top: -30px; 
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__next {
        right: -35px;
        &--bottom{
            top: auto;
            bottom: -15px; 
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    // Forzo la slider con tutti gli elementi alti uguali
    .slick-track {
        display: flex !important;
    }


    // Formatto icone sopra le varie slider 
    .fa-youtube-play, .fa-search{
        color: $white;
        position: absolute;
        text-shadow: 0 0 20px rgba($black, .8);
        font-size: 24px;
        z-index:100;
    }

    .fa-search {
        right: 10px;
        bottom: 10px;
    }

    .fa-youtube-play{
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 50px;
    }
 }