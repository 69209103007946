.landing {
    margin: auto;
    h1{
        color: $theme-color !important;
    }
    .contact__container{
        box-shadow: 0 0 20px rgba($black,.15);
        background-color: $white !important;
        border-radius: 0 !important;
    }
}

.page-template-page-LANDING{
    .site-content{
        display: flex;
        padding: 20px !important;
        margin: 0  !important;
        min-height: 100vh;
        background-color: $concrete;
    } 
}