.button {
    box-sizing: border-box;
    padding: 10px 15px;
    border-radius: 10px;
    display: inline-block;
    color: $white;
    cursor: pointer;
    font-weight: $font-weight-semibold;
    text-decoration: none !important;
    font-family: $font-secondary;
    font-size: 16px;
    border: 2px solid transparent;
    transition: background-color .3s linear, box-shadow .3s linear, color .3s linear;
    @include media-breakpoint-up(lg) {
        padding: 15px 25px;
        font-size: $body-font-size;
    }
    &--small-padding {
        padding: 0.375rem 0.75rem !important;
        border-radius: 0.25rem;
    }
    &.orange {
        color: $theme-color !important;
        background-color: $white;
        border: 2px solid $theme-color;
        @include media-breakpoint-up(lg) {
            color: $white !important;
            background-color: $theme-color;
        }
    }
    &.sandy-brown {
        background-color: $sandy-brown;
    }
    &.white {
        background-color: $white;
        color: $theme-color !important;
        box-shadow: 0 0 10px rgba($black, .15);
    }
    &:hover {
        color: $white;
        box-shadow: 0 0 10px rgba($theme-color, .65);
    }
}