// Form contatti
.contact{
    p, label{
        color: $dove-gray;
    }
    p:empty{
        margin:0;
        display:none;
    }
    small{
        display: block;
        color: $theme-color;
    }
    &__container{
        border-radius: 15px;
        padding: 30px;
        background-color: $theme-gray;
        margin: 0 15px;
        max-width: none;

        @include media-breakpoint-up(sm) {
            max-width: 510px;
            margin: 0 auto;
        }

        @include media-breakpoint-up(md) {
            max-width: 690px
        }

        @include media-breakpoint-up(lg) {
            max-width: 990px
        }

        @include media-breakpoint-up(xl) {
            max-width: 1170px
        }
    } 
    &__title{
        font-size: 26px;
        font-weight: $font-weight-bold;
    }

    &__btn{
        margin-top: 30px;
    }

    // Formattazione privacy data-one ----------

    #mm-prv-wdg + p {
        margin-bottom: 0;
        height: 0;
    }

    #mm-prv-wdg img:first-of-type{
        position: absolute;
    }

    .privacy-paragraphs .paragraph-title{
        padding: 0;
        margin-bottom: 3px;
    }

    .mm-prv-agree-submit-container{
        margin-top: 20px;
    } 
    
    @each $lang, $value in $custom-file-text {
        .custom-file:lang(#{$lang}) .custom-file-label::after {
          content: $value;
        }
    }

    &__background {
        .col-md-6:first-child {
           margin-bottom: 10px;
            @include media-breakpoint-up(md) { 
                background-color: $alto;
                margin: -30px 0 0 -15px ;
                padding: 30px 15px 0 30px;
                border-top-left-radius: 15px;
                box-shadow: inset 0 0 30px rgba($black, .08);
            }
        }
    }

    .font-weight-bold {
        color: $black;
    }
} 