@mixin anim_all {
    transition: all 0.3s linear;
}

@mixin visibile {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
}

@mixin hidden {
    visibility: hidden;
    opacity: 0;
}

@mixin triangolo {
    @include anim_all;
    content: '';
    position: absolute;
    bottom: -20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid $main-nav-color;
    opacity: 0;
    z-index: 20;
}

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
  }