.visual {
    $visual-min-height: 480px;
    $fs-xs-h1-t: 26px;
    $fs-h1-t: 40px;
    $lh-h2-bottom: 30px;
    $desc-line-height-top: 30px;
    ////
    display: block;
    // width: 100% !important;
    position: relative;
    //margin-top: $pad-top;
    min-height: $visual-min-height;
    height: calc(100vh - #{$pad-top});
    @include media-breakpoint-up(lg) {
        //margin-top: $pad-lg-top;
        height: calc(100vh - #{$pad-lg-top});
    }
    &__item {
        // width: 100% !important;
        position: relative;
        min-height: $visual-min-height;
        height: calc(100vh - #{$pad-top});
        @include media-breakpoint-up(lg) {
            height: calc(100vh - #{$pad-lg-top});
        }
        p {
            margin: 0;
        }
        &__bg {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, .5);
        }
        // TOP
        &__top {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &__h1 {
            color: $white;
            font-family: $font-primary;
            font-size: $fs-xs-h1-t;
            font-weight: $font-weight-bold;
            padding-bottom: 15px;
            line-height: $desc-line-height-top;
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
                font-size: $fs-h1-t;
                padding-bottom: 30px;
            }
        }
        &__dest {
            color: $white;
            font-size: $body-font-size;
            font-weight: $font-weight-normal;
            @include media-breakpoint-up(lg) {
                font-size: $menu-font-size;
            }
        }
        // BOTTOM
        &__bottom {
            background-color: $theme-gray;
            @include media-breakpoint-up(lg) {
                background: linear-gradient(to right, transparent 65%, $theme-gray 65%);
            }
        }
        &__content {
            border-top-left-radius: 10px;
            background-color: $theme-gray;
            justify-content: space-between;
            padding: 0;
            @include media-breakpoint-up(md) {
                padding-top: 15px;
                padding-left: 15px;
                padding-bottom: 15px;
            }
            align-items: center;
            display: flex;
            >div {
                padding: 15px;
                @include media-breakpoint-up(md) {
                    max-width: 33.333%;
                }
            }
        }
        &__h2 {
            font-family: $font-primary;
            font-size: $body-font-size;
            line-height: $lh-h2-bottom;
            @include media-breakpoint-up(md) {
                font-size: $menu-font-size;
            }
        }
        &__desb {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
        &__link {
            a {
                color: $theme-color;
                font-weight: $font-weight-semibold;
                text-decoration: none;
                white-space: nowrap;
            }
            i {
                margin-left: 10px;
            }
        }
    }

    &__next, &__prev {
        display: none !important;
        color: $white;
        font-size: 50px;
        position: absolute;
        transform: translateY(-50%);
        transition: opacity 400ms linear;
        opacity: .5;
        cursor: pointer;
        top: 50%;
        z-index: 8;
        &:hover {
            opacity: 1;
        }

        @include media-breakpoint-up(sm) {
            display: block !important;
        }
    }
    &__next{
        right: 30px;
    }
    &__prev{
        left: 30px;
    }
}