.visual-video{
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    
    @include media-breakpoint-up(lg) {
        padding-bottom:0;
        height: calc(100vh - #{$pad-lg-top});
    }

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up(lg) {
            top: 50%; 
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-width: 100%; 
            min-height: 100%; 
            width: auto; 
            height: auto;
            z-index: -1000; 
        }
    }
}