.blocchi-testuali{
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    display: flex;
    margin: 30px -10px;

    &--center{
        justify-content: center;
    }

    &__item{
        flex: 0 1 auto;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            width: 33.33332%;
        }

        /*@include media-breakpoint-up(lg) {
            width: 25%;
        }*/

        &__title{
            font-size: 40px;
            font-family: $font-primary;
            color: $san-marino !important;
            font-weight: $font-weight-bold;

            &--small{
                font-size: 26px; 
            }
        }

        &__content{
            margin:30px;
        }

        &--small{
            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
}