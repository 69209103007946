.video{
   
    &__filtri{
        margin-bottom: 30px;
    }

    &__tabs {
        display:flex;
        margin-right: 20px;
    }

    &__item{
        margin-bottom: 20px;
    }

    &__nav{
        text-decoration: none !important;
        padding: 0 0 20px;
        margin-right: 40px;
        &.active{
            color: $theme-color;
        }
    }

    &__link{
        display: block;
        position: relative;
        &::after{
            position: absolute; 
            content: "";
            background-color: rgba($black, .1);
            top: 0;
            left:0;
            width: 100%;
            height: 100%;
        }
    }

    &__foto{
        position: relative;
        
        i{
            position: absolute;
            color: $theme-yt-color;
            font-size: 3em;
            top:50%;
            left:50%;
            z-index: 2;
            transform: translateY(-50%) translateX(-50%);
            @include media-breakpoint-up(lg) {
                font-size: 4em;
            }
            
            &::after{
                transform: translateY(-50%) translateX(-50%);
                content: "";
                background-color: $white;
                position:absolute;
                z-index: -1;
                top: 50%;
                left: 50%;
                width: 25px;
                height: 25px;
            }
        }
    }

    &__titolo {
        cursor: pointer;
        font-size: 18px;
        @include media-breakpoint-up(lg) {
            font-size: 22px;
        }
    }

    &__descrizione{
        color: $dove-gray;
    }
}