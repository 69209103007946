.footer {
    margin-top: 40px;
    background-color: $bg-footer;
    padding: 30px 0;
    font-size: 15px;
    p {
        color: $white;
        margin: 0;
    }
    a {
        color: $white;
        text-decoration: none;
        &:hover {
            color: $theme-color;
        }
    }
    &__company {
        display: flex;
    }
    &__logo {
        margin-right: 30px;
    }
    &__social {
        margin-top: 25px;
        display: flex;
        align-items: center;
        flex-wrap:wrap;
        p {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
                margin-bottom: 10px; 
            }
        }
        >* {
            margin-right: 10px !important;
            white-space: nowrap;
        }
        a {
            text-transform: capitalize;
            align-items: center;
            display: flex;
            margin-bottom: 10px; 
        }
        img {
            height: 32px;
            margin-right: 5px;
        }
    } 
    &__iva {
        padding-left: 90px;
        @include media-breakpoint-up(md) {
            padding-left: 15px;
        }
    }
    &__menu {
        margin: 30px 0 0;
        padding-left:75px;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-breakpoint-up(md) {
            margin: 60px 0 0;
            flex-direction: row;
            font-size: 14px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }

        a {
            text-decoration: none;
        }
    }

    &__newsletter{
        padding-top: 30px;
        padding-left: 90px;
        @include media-breakpoint-up(md) {
            padding-left: 77px;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-left: 15px;
        }
        &__title{
            text-transform: uppercase;
            margin-bottom:10px !important;
        }
        &__text{
            margin-bottom: 10px !important;
        }
        .form-control{
            background-color: $dove-gray;
            color: $white !important;
            border-color: $dove-gray !important;
        }

        @include placeholder {
            color: $concrete;
            opacity: .5;
        }
    }
    &__por{
        display: flex;
        justify-content: center;
        align-items: center;
        &__img,
        &__text{
            font-size: 14px;
            line-height: 1.3;
            margin: 10px !important;
            a{
                font-weight: $font-weight-semibold
            }
        }
    }
}