.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background-color: $theme-color;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  img{
    content: '';
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
    margin: 3px 0 0 3px;
    position: absolute;
    border-radius: 50%;
  }
}


.marker-cluster div {
	width: 30px;
  height: 30px;
  line-height: 30px;
  color: $white;
  font-weight: 700;
  text-align: center;
  position: relative;
  font-size: 16px;
  border-radius: 50%;
  &::after {
    content: "";
    width: 40px;
    height: 40px;
    z-index:-1;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    opacity: .2;
    background-color: inherit;
    border-radius: 50%;;
  }
}
