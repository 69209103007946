html {
    overflow-x: hidden;
    box-shadow: none !important;
    -webkit-font-smoothing: antialiased;
}

body {
    overflow-x: hidden;
    background-color: $white;
    color: $color-font-default;
    font-family: $font-secondary;
    line-height: $body-line-height;
    margin: 0;
 
    font-size: $body-font-size-small;
    @include media-breakpoint-up(md) {
        font-size: $body-font-size;
    }

    a {
        outline: none !important;
    }
    &.menu-open {
        overflow-y: scroll;
        max-height: 100vh;
        header {
            overflow-y: scroll;            
            max-height: 100vh;
        }
    }

    
}

img { max-width: 100%; }

.site-content {
    margin-top: $pad-top;
    padding: 30px 0;
    @include media-breakpoint-up(lg) {
        padding: 50px 0;
        margin-top: $pad-lg-top;
    }
    &--home{
        padding-top:0;
    }
}

.no-pad{
    padding: 0 15px 0 0; 
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
        padding: 0; 
    } 
}