.search{

    &__article{
        border-bottom: 1px dotted $dove-gray;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child{
            border: none; 
            margin-bottom: 0;
            padding-bottom: 0;
        } 
 
        &__btn{
            margin-top: 20px;
        }

        &__highlight{
            background-color: $sandwisp;
        }
    }
} 