.content-home{
    $this: &;
    padding-top: $pad-top-section/2;
    @include media-breakpoint-up(lg) {
        order: $pad-top-section;
    }

    &__title {
        margin-bottom: 20px !important;
    }

    &__links {
        list-style: none;
        padding: 0;
        margin: 10px 0 0;
        display: flex;
        flex-wrap:wrap;
    }

    &__link {
        flex: 0 1 auto;
        margin-bottom: 20px;
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }

    a {
        text-decoration:none;
        display: flex;
        align-items: center;
    }

    &__icona {
        overflow: hidden;
        margin-right: 20px;
        background-color: $sandy-brown;
        border-radius: 50%;
        line-height:64px;
        text-align: center;
        height:64px;
        width:64px;
        img{ 
            display:inline-block;
        }
    }

    &__label{
        font-weight: $font-weight-semibold;
    }

    &__arrow{
        margin-left: auto;
        font-size: 40px;
        margin-right: 15%;
    }
}